import { isMobile } from '../utils/index'
export default {
  address: '高雄市鼓山區至聖路320號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7363.967493584655!2d120.3026645!3d22.6543946!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xda79e4871eca0e63!2z5Lqs5Z-OSUZD5o6l5b6F5Lit5b-D!5e0!3m2!1szh-TW!2stw!4v1626847811438!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/tq7tpdZH2jiHTb5q7',
  phone: '07-5550077',
  fbLink: 'https://www.facebook.com/%E4%BA%AC%E5%9F%8EIFC-102863098673350',
  fbMessage: 'https://m.me/102863098673350/',
  caseName: '京城IFC',
  indigatorLength: 10,

  houseInfos: [
    ['投資興建', '京成建設股份有限公司'],
    ['建築規劃', '張文明建築師事務所'],
    ['外觀設計', '張文明建築師事務所'],
    ['建築結構', '鑫龍土木結構聯合技師事務所'],
    ['公設規劃', '京城集團規劃部'],
    ['景觀規劃', '蕥園景觀設計有限公司'],
    ['燈光設計', '日光設計Art Light Design'],
    ['工程營造', '百鋐營造股份有限公司'],
    ['企劃銷售', '上揚國際建築團隊'],
  ],
  
  gtmCode: ['TDJZ5DF'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: isMobile ? '預約賞屋' : '預約賞屋',
    subTitle: ''
  }
}
