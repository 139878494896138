export default [
  					
  // { name: '首頁 ', imgSrc: '', subTitle: '', section: 'section1', svgSrc: '', offset: 0 },
  { name: '國際門牌', imgSrc: '', subTitle: '', section: 'section2', svgSrc: '', offset: 0 },
  { name: '科技南進', imgSrc: '', subTitle: '', section: 'section3', svgSrc: '', offset: 0 },
  { name: '總部帝標', imgSrc: '', subTitle: '', section: 'section4', svgSrc: '', offset: 0 },
  { name: '複合機能', imgSrc: '', subTitle: '', section: 'section5', svgSrc: '', offset: 0 },
  { name: '置產雙贏', imgSrc: '', subTitle: '', section: 'section6', svgSrc: '', offset: 0 },
  { name: '智慧潮流', imgSrc: '', subTitle: '', section: 'section7', svgSrc: '', offset: 0 },
  { name: '大師團隊', imgSrc: '', subTitle: '', section: 'section8', svgSrc: '', offset: 0 },
  { name: '預約賞屋', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '', offset: 70 },
  // { name: '立即預約', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '', mobileOffset: 0, offset: 0 },
  // { name: '地圖導航', imgSrc: '', subTitle: '', section: 'google-map', svgSrc: '', mobileOffset: 0, offset: -150 },
]